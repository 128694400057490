import { HttpClient } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { BaseUrlService } from '../../services/base-url.service';
import type { IOrganizationWithUsers } from '@archery-scoring/models/organization.model';
import { Observable, of } from 'rxjs';
import { CrudServiceInterface } from '@owain/store-features/models/crud-service.model';

@Injectable({
  providedIn: 'root',
})
export class OrganisationService implements CrudServiceInterface<IOrganizationWithUsers> {
  private readonly httpClient: HttpClient = inject(HttpClient);
  private readonly baseUrlService: BaseUrlService = inject(BaseUrlService);

  private url: string = `${this.baseUrlService.getBaseUrl()}/api/organizations`;

  getEntities(): Observable<IOrganizationWithUsers[]> {
    return this.httpClient.get<IOrganizationWithUsers[]>(`${this.url}/users`);
  }

  getEntity(id: number): Observable<IOrganizationWithUsers> {
    // Not implemented
    return of(<IOrganizationWithUsers>{});
  }

  addEntity(organization: IOrganizationWithUsers): Observable<IOrganizationWithUsers> {
    // Not implemented
    return of(<IOrganizationWithUsers>{});
  }

  updateEntity(organization: IOrganizationWithUsers): Observable<IOrganizationWithUsers> {
    return this.httpClient.put<IOrganizationWithUsers>(`${this.url}/about/${organization.id}`, {
      image: organization.image,
      lat: organization.lat,
      long: organization.long,
      city: organization.city,
      country: organization.country,
      address: organization.address,
      website: organization.website,
      abouten: organization.abouten,
      aboutnl: organization.aboutnl,
    });
  }

  deleteEntity(organization: IOrganizationWithUsers): Observable<IOrganizationWithUsers> {
    // Not implemented
    return of(<IOrganizationWithUsers>{});
  }
}
