import { inject, Signal } from '@angular/core';
import {
  patchState,
  signalStoreFeature,
  SignalStoreFeature,
  withMethods,
  withState,
  WritableStateSource,
} from '@ngrx/signals';
import {
  EntityComputed,
  EntityState,
  NamedEntityComputed,
  NamedEntityState,
  setAllEntities,
} from '@ngrx/signals/entities';
import {
  EntitiesSortMethods,
  EntitiesSortState,
  NamedEntitiesSortMethods,
  NamedEntitiesSortState,
  Sort,
} from './entities-sort.model';
import { getWithEntitiesSortEvents, getWithEntitiesSortKeys, sortData } from './entities-sort.util';
import { EventBusService } from '@owain/eventbus/event-bus.service';
import { broadcast, withEventHandler } from '@owain/store-features/features/event-handler/event-handler';
import { getWithEntitiesKeys } from '@owain/store-features/utils/util';

/**
 * Generates necessary state, computed and methods for sorting locally entities in the store.
 *
 * Requires withEntities to be present before this function
 * @param config
 * @param config.defaultSort - The default sort to be applied to the entities
 * @param config.entity - The type entity to be used
 * @param config.collection - The name of the collection for which will be sorted
 *
 * @example
 * const entity = type<Product>();
 * const collection = 'products';
 * export const store = signalStore(
 *   { providedIn: 'root' },
 *   withEntities({ entity, collection }),
 *   withEntitiesSort({
 *     entity,
 *     collection,
 *     defaultSort: { field: 'name', direction: 'asc' },
 *   }),
 * );
 * // generates the following signals
 * store.productsSort - the current sort applied to the products
 * // generates the following methods
 * store.sortProductsEntities({ sort: { field: 'name', direction: 'asc' } }) - sorts the products entities
 */
export function withEntitiesSort<Entity extends { id: string | number }>(config: {
  defaultSort: Sort<Entity>;
  entity: Entity;
}): SignalStoreFeature<
  {
    state: EntityState<Entity>;
    computed: EntityComputed<Entity>;
    methods: {};
  },
  {
    state: EntitiesSortState<Entity>;
    computed: {};
    methods: EntitiesSortMethods<Entity>;
  }
>;
/**
 * Generates necessary state, computed and methods for sorting locally entities in the store.
 *
 * Requires withEntities to be present before this function
 * @param config
 * @param config.defaultSort - The default sort to be applied to the entities
 * @param config.entity - The type entity to be used
 * @param config.collection - The name of the collection for which will be sorted
 *
 * @example
 * const entity = type<Product>();
 * const collection = 'products';
 * export const store = signalStore(
 *   { providedIn: 'root' },
 *   withEntities({ entity, collection }),
 *   withEntitiesSort({
 *     entity,
 *     collection,
 *     defaultSort: { field: 'name', direction: 'asc' },
 *   }),
 * );
 * // generates the following signals
 * store.productsSort - the current sort applied to the products
 * // generates the following methods
 * store.sortProductsEntities({ sort: { field: 'name', direction: 'asc' } }) - sorts the products entities
 */
export function withEntitiesSort<Entity extends { id: string | number }, Collection extends string>(config: {
  defaultSort: Sort<Entity>;
  entity: Entity;
  collection?: Collection;
}): SignalStoreFeature<
  // TODO: we have a problem  with the state property, when set to any
  // it works but is it has a Collection, some methods are not generated, it seems
  // to only be accessible using store['filterEntities']
  // the workaround doesn't cause any issues, because the signals prop does work and still
  // gives the right error requiring withEntities to be used
  {
    state: NamedEntityState<Entity, any>;
    computed: NamedEntityComputed<Entity, Collection>;
    methods: {};
  },
  {
    state: NamedEntitiesSortState<Entity, Collection>;
    computed: {};
    methods: NamedEntitiesSortMethods<Collection, Collection>;
  }
>;
export function withEntitiesSort<Entity extends { id: string | number }, Collection extends string>({
  defaultSort,
  ...config
}: {
  defaultSort: Sort<Entity>;
  entity: Entity;
  collection?: Collection;
}): SignalStoreFeature<any, any> {
  const { entitiesKey } = getWithEntitiesKeys(config);
  const { sortEntitiesKey, sortKey } = getWithEntitiesSortKeys(config);
  const { entitiesSortChanged } = getWithEntitiesSortEvents(config);
  return signalStoreFeature(
    withState({ [sortKey]: defaultSort }),
    withEventHandler(),
    withMethods((state: Record<string, Signal<unknown>>) => {
      const eventBusService: EventBusService = inject(EventBusService);

      return {
        [sortEntitiesKey]: ({ sort: newSort }: { sort?: Sort<Entity> } = {}) => {
          const sort = newSort ?? defaultSort;
          patchState(
            state as WritableStateSource<object>,
            {
              [sortKey]: sort,
            },
            config.collection
              ? setAllEntities(sortData(state[entitiesKey]() as Entity[], sort), {
                  collection: config.collection,
                })
              : setAllEntities(sortData(state[entitiesKey]() as Entity[], sort))
          );

          broadcast(state, entitiesSortChanged({ sort }));
          eventBusService.cast(`${config.collection}s:sort`);
        },
      };
    })
  );
}
