import { inject, isDevMode, PendingTasks, Signal, Type } from '@angular/core';
import { patchState, SignalStoreFeature, signalStoreFeature, withMethods, WritableStateSource } from '@ngrx/signals';
import {
  addEntity,
  EntityId,
  EntityState,
  removeAllEntities,
  removeEntity,
  setAllEntities,
  setEntity,
} from '@ngrx/signals/entities';
import { rxMethod } from '@ngrx/signals/rxjs-interop';
import { Observable, pipe, switchMap } from 'rxjs';
import { tapResponse } from '@ngrx/operators';
import { HttpErrorResponse } from '@angular/common/http';
import { CrudServiceInterface } from '@owain/store-features/models/crud-service.model';
import {
  CallState,
  getCallStateKeys,
  setError,
  setInitial,
  setLoaded,
  setLoading,
} from '@owain/store-features/features/call-state/call-state.feature';
import { broadcast, withEventHandler } from '@owain/store-features/features/event-handler/event-handler';
import { createEvent, props } from '@owain/store-features/features/event-handler/event-handler.util';

type Entity = { id: EntityId };

function capitalize(str: string): string {
  return str ? str[0].toUpperCase() + str.substring(1) : str;
}

function getDataServiceKeys(options: { collection?: string }) {
  const resetEntitiesKey = options.collection ? `reset${capitalize(options.collection)}s` : 'resetEntities';
  const getEntitiesKey = options.collection ? `get${capitalize(options.collection)}s` : 'getEntities';
  const addEntityKey = options.collection ? `add${capitalize(options.collection)}` : 'addEntity';
  const updateEntityKey = options.collection ? `update${capitalize(options.collection)}` : 'updateEntity';
  const deleteEntityKey = options.collection ? `delete${capitalize(options.collection)}` : 'deleteEntity';

  // TODO: Take these from @ngrx/signals/entities, when they are exported
  const entitiesKey = options.collection ? `${options.collection}Entities` : 'entities';
  const entityMapKey = options.collection ? `${options.collection}EntityMap` : 'entityMap';
  const idsKey = options.collection ? `${options.collection}Ids` : 'ids';

  return {
    resetEntitiesKey,
    getEntitiesKey,
    addEntityKey,
    updateEntityKey,
    deleteEntityKey,
    entitiesKey,
    entityMapKey,
    idsKey,
  };
}

export type NamedDataServiceMethods<Collection extends string, E extends Entity> = {
  [K in Collection as `reset${Capitalize<K>}s`]: () => void;
} & {
  [K in Collection as `get${Capitalize<K>}s`]: () => void;
} & {
  [K in Collection as `add${Capitalize<K>}`]: (entity: E | Signal<E> | Observable<E>) => void;
} & {
  [K in Collection as `update${Capitalize<K>}`]: (entity: E | Signal<E> | Observable<E>) => void;
} & {
  [K in Collection as `delete${Capitalize<K>}`]: (entity: E | Signal<E> | Observable<E>) => void;
};

export type DataServiceMethods<E extends Entity> = {
  resetEntities: () => void;
  getEntities: () => void;
  addEntity: (entity: E | Signal<E> | Observable<E>) => void;
  updateEntity: (entity: E | Signal<E> | Observable<E>) => void;
  deleteEntity: (entity: E | Signal<E> | Observable<E>) => void;
};

export function withDataService<
  E extends Entity,
  S extends CrudServiceInterface<E>,
  Collection extends string,
>(options: {
  loggerKey: string;
  key?: string | null;
  dataServiceType: Type<S>;
  collection: Collection;
}): SignalStoreFeature<
  {
    state: {};
    // These alternatives break type inference:
    // state: { callState: CallState } & NamedEntityState<E, Collection>,
    // state: NamedEntityState<E, Collection>,

    computed: {};
    methods: {};
  },
  {
    state: {};
    computed: {};
    methods: NamedDataServiceMethods<Collection, E>;
  }
>;
export function withDataService<E extends Entity, S extends CrudServiceInterface<E>>(options: {
  loggerKey: string;
  key?: string | null;
  dataServiceType: Type<S>;
}): SignalStoreFeature<
  {
    state: { callState: CallState } & EntityState<E>;
    computed: {};
    methods: {};
  },
  {
    state: {};
    computed: {};
    methods: DataServiceMethods<E>;
  }
>;

export function withDataService<
  E extends Entity,
  S extends CrudServiceInterface<E>,
  Collection extends string,
>(options: {
  loggerKey: string;
  key?: string | null;
  dataServiceType: Type<S>;
  collection?: Collection;
}): SignalStoreFeature<any, any> {
  const { loggerKey, key, dataServiceType, collection: prefix } = options;
  const { resetEntitiesKey, getEntitiesKey, addEntityKey, updateEntityKey, deleteEntityKey } =
    getDataServiceKeys(options);
  const { callStateKey } = getCallStateKeys({ collection: prefix });

  return signalStoreFeature(
    withEventHandler(),
    withMethods((store: Record<string, unknown> & WritableStateSource<object>) => {
      const dataService = inject(dataServiceType);
      const pendingTasks: PendingTasks = inject(PendingTasks);
      const sideEffectEvent = createEvent(
        key ? `${key}.sideEffect` : `${prefix}s.sideEffect`,
        props<{
          key: string;
          status: string;
        }>()
      );

      return {
        [resetEntitiesKey]: () => {
          const taskCleanup = pendingTasks.add();

          if (prefix) {
            patchState(store, removeAllEntities({ collection: prefix }));
            store[callStateKey] && patchState(store, setInitial(prefix));
          } else {
            patchState(store, removeAllEntities());
            store[callStateKey] && patchState(store, setInitial());
          }

          taskCleanup();
        },

        [getEntitiesKey]: rxMethod<void>(
          pipe(
            switchMap(() => {
              const taskCleanup = pendingTasks.add();

              if (prefix) {
                store[callStateKey] && patchState(store, setLoading(prefix));
              } else {
                store[callStateKey] && patchState(store, setLoading());
              }

              return dataService.getEntities().pipe(
                tapResponse({
                  next: (entities: E[]) => {
                    if (prefix) {
                      patchState(store, setAllEntities(entities, { collection: prefix }));
                    } else {
                      patchState(store, setAllEntities(entities));
                    }

                    broadcast(
                      store,
                      sideEffectEvent({
                        key: 'get',
                        status: 'success',
                      })
                    );

                    if (prefix) {
                      store[callStateKey] && patchState(store, setLoaded(prefix));
                    } else {
                      store[callStateKey] && patchState(store, setLoaded());
                    }
                  },
                  error: (err: HttpErrorResponse) => {
                    if (isDevMode()) {
                      console.error(`[${loggerKey}:${prefix}]`, err);
                    }

                    if (prefix) {
                      store[callStateKey] && patchState(store, setLoaded(prefix));
                    } else {
                      store[callStateKey] && patchState(store, setLoaded());
                    }

                    patchState(store, setError(err.message));
                    broadcast(
                      store,
                      sideEffectEvent({
                        key: 'get',
                        status: 'failure',
                      })
                    );
                  },
                  finalize: () => {
                    taskCleanup();
                  },
                })
              );
            })
          )
        ),

        [addEntityKey]: rxMethod<E>(
          pipe(
            switchMap((entity: E) => {
              const taskCleanup = pendingTasks.add();

              if (prefix) {
                store[callStateKey] && patchState(store, setLoading(prefix));
              } else {
                store[callStateKey] && patchState(store, setLoading());
              }

              return dataService.addEntity(entity).pipe(
                tapResponse({
                  next: (addedEntity: E) => {
                    if (prefix) {
                      patchState(store, addEntity(addedEntity, { collection: prefix }));
                    } else {
                      patchState(store, addEntity(addedEntity));
                    }

                    broadcast(
                      store,
                      sideEffectEvent({
                        key: 'add',
                        status: 'success',
                      })
                    );

                    if (prefix) {
                      store[callStateKey] && patchState(store, setLoaded(prefix));
                    } else {
                      store[callStateKey] && patchState(store, setLoaded());
                    }
                  },
                  error: (err: HttpErrorResponse) => {
                    if (isDevMode()) {
                      console.error(`[${loggerKey}:${prefix}]`, err);
                    }

                    if (prefix) {
                      store[callStateKey] && patchState(store, setLoaded(prefix));
                    } else {
                      store[callStateKey] && patchState(store, setLoaded());
                    }

                    patchState(store, setError(err.message));
                    broadcast(
                      store,
                      sideEffectEvent({
                        key: 'add',
                        status: 'failure',
                      })
                    );
                  },
                  finalize: () => {
                    taskCleanup();
                  },
                })
              );
            })
          )
        ),

        [deleteEntityKey]: rxMethod<E>(
          pipe(
            switchMap((entity: E) => {
              const taskCleanup = pendingTasks.add();

              if (prefix) {
                store[callStateKey] && patchState(store, setLoading(prefix));
              } else {
                store[callStateKey] && patchState(store, setLoading());
              }

              return dataService.deleteEntity(entity).pipe(
                tapResponse({
                  next: () => {
                    if (prefix) {
                      patchState(store, removeEntity(entity.id, { collection: prefix }));
                    } else {
                      patchState(store, removeEntity(entity.id));
                    }

                    broadcast(
                      store,
                      sideEffectEvent({
                        key: 'delete',
                        status: 'success',
                      })
                    );

                    if (prefix) {
                      store[callStateKey] && patchState(store, setLoaded(prefix));
                    } else {
                      store[callStateKey] && patchState(store, setLoaded());
                    }
                  },
                  error: (err: HttpErrorResponse) => {
                    if (isDevMode()) {
                      console.error(`[${loggerKey}:${prefix}]`, err);
                    }

                    if (prefix) {
                      store[callStateKey] && patchState(store, setLoaded(prefix));
                    } else {
                      store[callStateKey] && patchState(store, setLoaded());
                    }

                    patchState(store, setError(err.message));
                    broadcast(
                      store,
                      sideEffectEvent({
                        key: 'delete',
                        status: 'failure',
                      })
                    );
                  },
                  finalize: () => {
                    taskCleanup();
                  },
                })
              );
            })
          )
        ),

        [updateEntityKey]: rxMethod<E>(
          pipe(
            switchMap((entity: E) => {
              const taskCleanup = pendingTasks.add();

              if (prefix) {
                store[callStateKey] && patchState(store, setLoading(prefix));
              } else {
                store[callStateKey] && patchState(store, setLoading());
              }

              return dataService.updateEntity(entity).pipe(
                tapResponse({
                  next: (updatedEntity: E) => {
                    if (prefix) {
                      patchState(store, setEntity(updatedEntity, { collection: prefix }));
                    } else {
                      patchState(store, setEntity(updatedEntity));
                    }

                    broadcast(
                      store,
                      sideEffectEvent({
                        key: 'update',
                        status: 'success',
                      })
                    );

                    if (prefix) {
                      store[callStateKey] && patchState(store, setLoaded(prefix));
                    } else {
                      store[callStateKey] && patchState(store, setLoaded());
                    }
                  },
                  error: (err: HttpErrorResponse) => {
                    if (isDevMode()) {
                      console.error(`[${loggerKey}:${prefix}]`, err);
                    }

                    if (prefix) {
                      store[callStateKey] && patchState(store, setLoaded(prefix));
                    } else {
                      store[callStateKey] && patchState(store, setLoaded());
                    }

                    patchState(store, setError(err.message));
                    broadcast(
                      store,
                      sideEffectEvent({
                        key: 'update',
                        status: 'failure',
                      })
                    );
                  },
                  finalize: () => {
                    taskCleanup();
                  },
                })
              );
            })
          )
        ),
      };
    })
  );
}
